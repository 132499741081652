import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { GLOBAL } from "src/app/app.globals";

@Injectable()
export class MinWageConfigurationComponentService {
  constructor(
    private http: HttpClient,
  ) { }

  private dataSource = new BehaviorSubject<any>(null);
  currentData = this.dataSource.asObservable();


  shareData(data: any) {
    this.dataSource.next(data);
  }

  public getWageConfigurationList(reqObj: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.CLPMS_SP_LIST_MINIMUMWAGE}`;
    return this.http.post<any>(url, reqObj).pipe(map((response: any) => {
      if (response) {
        return response;
      } else {
        return [];
      }
    })
    );
  }

  public getCompany(): Observable<any[]> {
    const req = {
      Search: null,
      StatusOfIndustry: null,
      EntityType: null,
      Legalstatus: null,
      State: null,
      City: null,
      Country: null,
    };
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_COMPANY_MASTER_DATA_SP}`;
    return this.http.post<any[]>(url, req).pipe(map((response: any) => response));
  }

  public getPlant(): Observable<any[]> {
    const requestObj = { search: null, Company: 101, State: null, City: null, Country: null, UnitType: null, OwnerShip: null, LocatedAt: null, IsESICApplicable: null }
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_MASTER_DATA}`;
    return this.http.post<any[]>(url, requestObj);
  }

  public getCountry(): Observable<any[]> {
    const requestObj = { Search: "" }
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_Country_Master_LIST}`;
    return this.http.post<any[]>(url, requestObj);
  }
  
  public getState(country_code): Observable<any[]> {
    const requestObj = { Search: "", Country_Code: country_code }
    const url = `${GLOBAL.APIS.USER_MANAGEMENT.GET_STATES_OF_COUNTRY}`;
    return this.http.post<any[]>(url, requestObj);
  }

  public getAutoStateBind(plantCode, userId): Observable<any[]> {
    const requestObj = { Search: "", selectedField: null, State_Code: "", Plant_Code: plantCode, RequireType: 0, ActionType: 0, UserId: userId }
    const url = `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_STATE_DATA_SP}`;
    return this.http.post<any[]>(url, requestObj);
  }
  
  public getListOfComponent(userId): Observable<any[]> {
    const requestObj = { Company_Code: 101, Plant_Code: null, Contractor_Code: null, Client_Code: null, IsList: null, RequireType: 1, ActionType: 0, UserId: userId }
    const url = `${GLOBAL.APIS.PAYROLL_MANAGEMENT.GET_LIST_PAYROLL_COMPONENTS}`;
    return this.http.post<any[]>(url, requestObj);
  }

  public saveMinWageData(formData): Observable<any[]> {
    const url = `${GLOBAL.APIS.PAYROLL_MANAGEMENT.MINIMUM_WAGE_CONFIG_SAVE}`;
    return this.http.post<any[]>(url, formData);
  }
  
  public getMinWageData(id: number, userId: number): Observable<any[]> {
    const url = `${GLOBAL.APIS.PAYROLL_MANAGEMENT.GET_MINIMUM_WAGE_CONFIG_DATA}`;
    const data = {
      "Filter": {
        ID: id,
        RequireType: 2,
        ActionType: 0,
        UserId: userId
      }
    }
    return this.http.post<any[]>(url, data);
  }
}