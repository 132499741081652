import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SharedService } from "src/app/core/services";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class MinWageConfigurationComponentService {
  constructor(
    private http: HttpClient,
    private sharedService: SharedService
  ) { }

  public getWageConfigurationList(userId:number): Observable<any[]> {
    const requestObj = {RequireType:0,ActionType:0 ,UserID: userId }

    const url = 'https://ciplaapi.spikyarc.com/api/Common/List/MinimumWageConfigSettingList';
    return this.http.post<any[]>(url, requestObj);
  }

  public getCompany(userId:number): Observable<any[]> {
    const requestObj = { UserID: userId, Company_Code: "",Sys_Code: "", Name: "", ShortCode: "", ShortName: "", Email: "", Address1: "", Address2: "", Address3: "", ZipCode: "", Country_Code: "", State_Code: "", District_Code: "", City_Code: "", PostOffice_Code: "", StatementType: "DropDown" }

    const url = 'https://ciplaapi.spikyarc.com/api/Common/List/SP_Insert_Update_Delete_List_CompanyMaster';
    return this.http.post<any[]>(url, requestObj);
  }

  public getPlant(): Observable<any[]> {
    const requestObj = {search:null,Company:101, State:null,City:null,Country:null,UnitType:null,OwnerShip:null,LocatedAt:null,IsESICApplicable:null}

    const url = 'https://ciplaapi.spikyarc.com/api/Common/List/SP_Listing_PlantMaster_Filter';
    return this.http.post<any[]>(url,requestObj);
  }

  public getCountry(): Observable<any[]> {
    const requestObj = {Search: ""}

    const url = 'https://ciplaapi.spikyarc.com/api/Common/List/SP_COM_GetAllCountry';
    return this.http.post<any[]>(url, requestObj);
  }

  public getState(country_code): Observable<any[]> {
    const requestObj = {Search: "", Country_Code: country_code}

    const url = 'https://ciplaapi.spikyarc.com/api/Common/List/SP_COM_GetAllState';
    return this.http.post<any[]>(url, requestObj);
  }

  public getAutoStateBind(plantCode,userId): Observable<any[]> {
    const requestObj = {Search:"",selectedField:null,State_Code:"",Plant_Code:plantCode,RequireType:0,ActionType:0,UserId:userId}

    const url = 'https://ciplaapi.spikyarc.com/api/Common/List/SP_COM_GetStateList_V1';
    return this.http.post<any[]>(url, requestObj);
  }

  public getListOfComponent(userId): Observable<any[]> {
    const requestObj = {Company_Code:101,Plant_Code:null,Contractor_Code:null,Client_Code:null,IsList:null,RequireType:1,ActionType:0,UserId:userId}

    const url = 'https://ciplaapi.spikyarc.com/api/Common/List/SP_List_PayrollComponent';
    return this.http.post<any[]>(url, requestObj);
  }

  public saveMinWageData(formData): Observable<any[]> {
    const url = 'https://ciplaapi.spikyarc.com/api/Common/List/InsertUpdateMinimumWageConfigSetting';
    return this.http.post<any[]>(url, formData);
  }

  public getMinWageData(id:number,userId:number):Observable<any[]>{
    const url = 'https://ciplaapi.spikyarc.com/api/Common/MultiTableList/MinimumWageConfigSettingList';
    const data = {
      "Filter": {
        ID: id,
        RequireType: 2,
        ActionType: 0,
        UserId: userId
      }
    }
    return this.http.post<any[]>(url, data);
  }
}