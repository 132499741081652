import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GLOBAL } from '../app.globals';

@Injectable({
  providedIn: 'root'
})
export class MasterServiceService {

  constructor(
    private http: HttpClient
  ) { }

  public getMasterReportData(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTER_DATA}`;
    return this.http.get(url).pipe(map((response: any) => response.Data));
  }

  public createMasterReportData(model): Observable<any> {
    const url = `${GLOBAL.APIS.MASTER_DATA.ADD_MASTER_ITEM}`;
    return this.http.post(url, model);
  }

  public updateMasterReportData(model): Observable<any> {
    const url = `${GLOBAL.APIS.MASTER_DATA.UPDATE_MASTER_ITEM}`;
    return this.http.post(url, model);
  }

  public readMasterReportData(masteritemCode): Observable<any> {
    const params = {
      masteritemCode
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTER_ITEM}`;
    return this.http.post(url, { params });
  }

  public deleteMasterReportData(masteritemCode): Observable<any> {
    const params = {
      masteritemCode
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.DELETE_MASTER_ITEM}`;
    return this.http.post(url, { params });
  }
}
